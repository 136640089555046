import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ListingDetail from './pages/ListingDetail';
import AdminDashboard from './admin/pages/AdminDashboard';
import ListingsPage from './pages/ListingsPage'; // Import ListingsPage
import './styles/App.css';

const App = () => {
    const [supertype, setSupertype] = useState('');
    const [type, setType] = useState('');

    const resetListings = () => {
        // Implement reset listings logic if needed
    };

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route 
                        path="/" 
                        element={
                            <HomePage 
                                setSupertype={setSupertype} 
                                setType={setType} 
                                resetListings={resetListings} 
                            />
                        } 
                    />
                    <Route 
                        path="/listings/:certNumber"
                        element={
                            <ListingDetail
                                setSupertype={setSupertype}
                                setType={setType}
                                resetListings={resetListings}
                            />
                        }
                    />
                    <Route
                        path="/admin"
                        element={
                            <AdminDashboard
                                setSupertype={setSupertype}
                                setType={setType}
                                resetListings={resetListings}
                            />
                        }
                    />
                    {/* New Routes for Each Supertype */}
                    <Route 
                        path="/pokemon" 
                        element={<ListingsPage supertype="pokemon" />} 
                    />
                    <Route 
                        path="/trainer" 
                        element={<ListingsPage supertype="trainer" />} 
                    />
                    <Route 
                        path="/energy" 
                        element={<ListingsPage supertype="energy" />} 
                    />
                    <Route 
                        path="/artists/:name" 
                        element={<ListingsPage />} 
                    />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
