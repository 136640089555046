import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import './ListingDetail.css';

const ListingDetail = () => {
    const { certNumber } = useParams();
    const [listing, setListing] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [flavorTextDisplay, setFlavorTextDisplay] = useState('');
    const [isExpanded, setIsExpanded] = useState(false); // State to manage pane expansion
    const listingInfoRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchListing = async () => {
            try {
                const response = await fetch(`https://api.gradies.com/listings?certNumber=${certNumber}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }

                const result = await response.json();
                if (result.status === 'success' && result.listings.length > 0) {
                    setListing(result.listings[0]);
                } else {
                    throw new Error('Listing not found');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchListing();
    }, [certNumber]);

    useEffect(() => {
        if (listing && listing.cert.card.flavorText) {
            let i = 0;
            const text = listing.cert.card.flavorText;
            const interval = setInterval(() => {
                setFlavorTextDisplay((prev) => prev + text[i]);
                i++;
                if (i === text.length) clearInterval(interval);
            }, 30); // Adjust the speed here
        }
    }, [listing]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading listing: {error}</p>;
    if (!listing) return <p>No listing found</p>;

    const { cert, price, cert: { card } } = listing;
    const frontImageUrl = cert.cert_images.front_image_url || 'https://via.placeholder.com/200';
    const grade = cert.grade || 'N/A';
    const name = card?.name || cert.name || 'Unknown';
    const certSet = cert.set || 'Unknown';
    const cardSet = card?.set || 'Unknown';
    const flavorText = card?.flavorText || '';
    const illustrator = card?.artist || 'Unknown';

    const togglePane = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <Header setSupertype={() => {}} setType={() => {}} resetListings={() => {}} />
            <Layout>
                <div className="listing-detail">
                    <div
                        className={`listing-info glass-pane ${isExpanded ? 'expanded' : 'collapsed'}`}
                        ref={listingInfoRef}
                        onClick={togglePane} // Toggle pane on click
                    >
                        <div className="name-grade">
                            <h1><i>{name}</i></h1>
                            <span className="grade">{grade}</span>
                        </div>
                        <p><i>{cardSet.series === cardSet.name ? cardSet.name : cardSet.series + ": " + cardSet.name}</i></p>
                        <p><i>{certSet}</i></p>
                        <div className="illustrator-set-container">
                            <p className="illustrator">Illustrated by <Link to={`/artists/${illustrator}`}>{illustrator}</Link></p>
                            <p className="set-number">#{card.number}/{card.set.printedTotal}</p>
                        </div>
                        {flavorText && <div className="flavor-text-box"><p className="flavor-text"><i>{flavorTextDisplay}</i></p></div>}
                        <div className="checkout">
                            <div className="price-details">
                                <p>Price <span>${price.toFixed(2)}</span></p>
                                <p>Sales Tax <span>${(price * 0.1).toFixed(2)}</span></p>
                                <hr />
                                <p className="total">Total <span>${(price + (price * 0.1)).toFixed(2)}</span></p>
                            </div>
                            <br></br><br></br>
                            <div className="payment-details">
                                <h2>Payment Details</h2>
                                <div className="payment-fields">
                                    <div>
                                        <label>Card holder name</label>
                                        <input type="text" placeholder="Enter Your First Name..." />
                                    </div>
                                    <div>
                                        <label>Card number</label>
                                        <input type="text" placeholder="Enter Your Card Number..." />
                                    </div>
                                    <div>
                                        <label>CVV</label>
                                        <input type="text" placeholder="Example: 4567" />
                                    </div>
                                    <div>
                                        <label>Expiration Date</label>
                                        <input type="text" placeholder="MM/YY" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listing-image">
                        <img src={frontImageUrl} alt={name} />
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default ListingDetail;
